import {
  MANAGE_USER_ERROR,
  MANAGE_USER_REQUEST,
  MANAGE_USER_SUCCESS,
} from "../actions";

const INIT_STATE = {
  user_loading: false,
  user_error: null,
  user_success: null,
  user_action: null,
  user_request_type: null,
  user: null,
};

const user_reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_USER_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        user_request_type: action.request_type,
      };
    case MANAGE_USER_SUCCESS:
      if (action.request_type === "LOGIN") {
        return {
          ...state,
          user_loading: false,
          user_success: true,
          user: action.payload,
        };
      } else if (action.request_type === "UTILS") {
        return {
          ...state,
          user_loading: false,
          user_success: true,
          utils: action.payload,
        };
      }
      break;
    case MANAGE_USER_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default user_reducer;
