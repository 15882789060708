import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import axios from "axios";
import { Suspense } from "react";
if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:2020/api/v1";
} else {
  axios.defaults.baseURL = "https://clinicapi.ideaclinics.com/api/v1";
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
