import SignIn from "./pages/SignIn";
import React from "react";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { CheckAuth } from "./helpers/utils";
import { Suspense } from "react";

const Dashboard = React.lazy(() => import("./views/Dashboard"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            {CheckAuth() === true ? (
              <Route path="/dashboard" component={Dashboard} />
            ) : (
              <Route path="/sign-in" component={SignIn} />
            )}

            <Route path="*">
              {CheckAuth() === true ? (
                <Redirect to="/dashboard/revenue" />
              ) : (
                <Redirect to="/sign-in" />
              )}
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
