import decode from "jwt-decode";
import moment from "moment";

export const setCurrentUser = (token) => {
  if (token) {
    localStorage.setItem("token", token);
  } else {
    localStorage.clear();
    window.location.href = "/";
  }
};

export const token = () => {
  return localStorage.getItem("token");
};

export const header = () => {
  return {
    headers: {
      Authorization: "Bearer " + token(),
    },
  };
};

export const Multipartheader = () => {
  return {
    headers: {
      Authorization: "Bearer " + token(),
      "content-type": "multipart/form-data",
      Accept: "application/json",
      processData: false,
    },
  };
};
export const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const CheckAuth = () => {
  const current_user = localStorage.getItem("token");
  if (!current_user) {
    return false;
  }
  try {
    let token = current_user;
    if (token) {
      const { exp } = decode(token);
      if (exp < new Date().getTime() / 1000) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } catch (e) {}
};

export const signOut = () => {
  localStorage.removeItem("token");
  window.location.href = "/sign-in";
};
export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const isMobile = () => {
  return window.screen.width <= 450;
};

export const _groupBy_ = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const _Unique_ = (a) => {
  return a.sort().filter(function (value, index, array) {
    return index === 0 || value !== array[index - 1];
  });
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
};

export const joinArray = (indexName, defaults, ...arrays) => {
  const map = new Map();
  arrays.forEach((array) => {
    array.forEach((item) => {
      map.set(item[indexName], Object.assign(item, map.get(item[indexName])));
    });
  });
  return [...map.values()].map((item) => Object.assign({}, defaults, item));
};

export const downLoadFile = (url, file_name) => {
  var link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute("download", file_name);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeObjectNulls = (data) => {
  let obj = {};
  Object.keys(data).map((key) => {
    if (data[key]) {
      obj[key] = data[key];
    }
  });

  return obj;
};

export const sortByStatus = (appointment_list) => {
  let sorted_appointments_list = [];
  const statusList = {
    BOOKED: [],
    ARRIVED: [],
    "ON-GOING": [],
    REVIEWED: [],
    COMPLETED: [],
    CANCELLED: [],
  };
  [...appointment_list].map((appointment) => {
    appointment.status && statusList[appointment.status].push(appointment);
  });
  Object.keys(statusList).map((key) => {
    statusList[key] = statusList[key].sort((a, b) => {
      let [aHours, aMinutes] = a.start_time.split(":");
      let [bHours, bMinutes] = b.start_time.split(":");
      let aDate = moment(a.date).hours(aHours).minutes(aMinutes).valueOf();
      let bDate = moment(b.date).hours(bHours).minutes(bMinutes).valueOf();
      return aDate > bDate ? 1 : -1;
    });
    sorted_appointments_list = [
      ...sorted_appointments_list,
      ...statusList[key],
    ];
  });
  return sorted_appointments_list;
};
