import {
  MANAGE_DASHBOARD_ERROR,
  MANAGE_DASHBOARD_REQUEST,
  MANAGE_DASHBOARD_SUCCESS,
} from "../actions";

const INIT_STATE = {
  dashboard_loading: false,
  dashboard_error: null,
  dashboard_success: null,
  dashboard_action: null,
  dashboard_request_type: null,
  revenue_report: [],
  compared_revenue_report: [],
  source_revenue_report: [],
  staff_revenue_report: [],
};

const dashboard_reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard_loading: true,
        dashboard_error: null,
        dashboard_success: null,
        dashboard_action: action.type,
        dashboard_request_type: action.request_type,
      };
    case MANAGE_DASHBOARD_SUCCESS:
      if (action.request_type === "REVENUE_REPORT") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
          revenue_report: action.payload,
        };
      } else if (action.request_type === "COMPARED_REVENUE_REPORT") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
          compared_revenue_report: action.payload,
        };
      } else if (action.request_type === "SOURCE_REVENUE_REPORT") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
          source_revenue_report: action.payload,
        };
      } else if (action.request_type === "STAFF_REVENUE_REPORT") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
          staff_revenue_report: action.payload,
        };
      }
      break;
    case MANAGE_DASHBOARD_ERROR:
      return {
        ...state,
        dashboard_loading: false,
        dashboard_error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default dashboard_reducer;
