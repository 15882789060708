/* eslint-disable no-unused-vars */
import { all } from "redux-saga/effects";

import userSaga from "./user/saga";
import dashboardSaga from "./dashboard/saga";


export default function* rootSaga(getState) {
  yield all([
  userSaga(),
  dashboardSaga()
  ]);
}
