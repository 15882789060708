import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";
import { MANAGE_USER_REQUEST } from "../actions";

import {
  GET,
  POST,
  DELETE,
  LIST,
  PATCH,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import { manageUserError, manageUserSuccess } from "./action";

export function* watchManageUser() {
  yield takeLatest(MANAGE_USER_REQUEST, manageUser);
}

function* manageUser({ payload, request_type }) {
  let url, response;

  try {
    if (request_type === "LOGIN") {
      url = `/user/dashboard/login`;
      response = yield call(POST, url, payload);
    } else if (request_type === "UTILS") {
      url = `/utils/client`;
      response = yield call(POST, url, payload.data);
    } else {
      yield put(manageUserError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageUserError(response.message));
    } else {
      if (request_type === "LOGIN") {
        localStorage.setItem("token", response.token);
        window.location.reload();
      }
      yield put(manageUserSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageUserError(error));
  }
}
export default function* rootSaga() {
  yield all([fork(watchManageUser)]);
}
