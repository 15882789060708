import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { MANAGE_DASHBOARD_REQUEST } from "../actions";

import { POST } from "../api";
import { manageDashboardError, manageDashboardSuccess } from "./action";

export function* watchManageDashboard() {
  yield takeLatest(MANAGE_DASHBOARD_REQUEST, manageDashboard);
}

function* manageDashboard({ payload, request_type }) {
  let url, response;

  try {
    if (request_type === "REVENUE_REPORT") {
      url = `/dashboard/revenue`;
      response = yield call(POST, url, payload);
    } else if (request_type === "COMPARED_REVENUE_REPORT") {
      url = `/dashboard/revenue`;
      response = yield call(POST, url, payload);
    } else if (request_type === "SOURCE_REVENUE_REPORT") {
      url = `/dashboard/sourcerevenue`;
      response = yield call(POST, url, payload);
    } else if (request_type === "STAFF_REVENUE_REPORT") {
      url = `/dashboard/staffrevenue`;
      response = yield call(POST, url, payload);
    } else {
      yield put(manageDashboardError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageDashboardError(response.message));
    } else {
      yield put(manageDashboardSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageDashboardError(error));
  }
}
export default function* rootSaga() {
  yield all([fork(watchManageDashboard)]);
}
