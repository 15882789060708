
export const MANAGE_USER_REQUEST = "MANAGE_USER_REQUEST";
export const MANAGE_USER_SUCCESS = "MANAGE_USER_SUCCESS";
export const MANAGE_USER_ERROR = "MANAGE_USER_ERROR";


export const MANAGE_DASHBOARD_REQUEST = "MANAGE_DASHBOARD_REQUEST";
export const MANAGE_DASHBOARD_SUCCESS = "MANAGE_DASHBOARD_SUCCESS";
export const MANAGE_DASHBOARD_ERROR = "MANAGE_DASHBOARD_ERROR";

export * from "./dashboard/action";
export * from "./user/action";