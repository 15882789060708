import { MANAGE_DASHBOARD_ERROR, MANAGE_DASHBOARD_REQUEST, MANAGE_DASHBOARD_SUCCESS } from "../actions";

export const manageDashboardRequest = (request) => ({
    type: MANAGE_DASHBOARD_REQUEST,
    payload: request,
    request_type: request.request_type,
  });
  export const manageDashboardSuccess = (response, request_type) => ({
    type: MANAGE_DASHBOARD_SUCCESS,
    payload: response,
    request_type: request_type,
  });
  export const manageDashboardError = (message) => ({
    type: MANAGE_DASHBOARD_ERROR,
    payload: message,
  });