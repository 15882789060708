import React from "react";
import styles from "../assets/styles/signIn.module.css";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import { connect } from "react-redux";
import { manageUserRequest } from "../redux/actions";

const { Title } = Typography;
const { Content } = Layout;
const SignIn = (props) => {
  const { user_loading, user_request_type } = props.user_reducer;
  const onFinish = (values) => {
    props.manageUserAsync({
      request_type: "LOGIN",
      ...values,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <div className={styles.row}>
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                className="sign-img"
                style={{ padding: 12 }}
                xs={{ span: 24 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <img src="https://vaccines.ideaclinics.com/logo.png" alt="" />
              </Col>
              <Col
                className={styles.second_col}
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Title className="mb-15">Sign In</Title>

                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="Username" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      loading={user_loading && user_request_type === "LOGIN"}
                      htmlType="submit"
                      style={{
                        width: "100%",
                        color: "white",
                        background: "#f17538",
                        border: "none",
                      }}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = ({ user_reducer }) => {
  return { user_reducer };
};

const mapDispatchToProps = {
  manageUserAsync: manageUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
